import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Link } from 'gatsby'
import { getWindow } from '../utils'
import Prism from '../utils/prism.js'
import '../utils/prism.css'
import './blogPost.scss'

const makeTwitterLink = username => 'https://twitter.com/' + username

const MetaContent = ({ data }) => {
  const author = data.author.html && data.author.raw[0].text
  return (
    <>
      <span className="BlogPost_published">{data.publish_date}</span>
      {author.startsWith('@') ? (
        <a
          href={makeTwitterLink(author)}
          target="_blank"
          className="BlogPost_author"
        >
          {author}
        </a>
      ) : (
        <span className="BlogPost_author">{author}</span>
      )}
    </>
  )
}

const BlogPost = ({
  pageContext: {
    post: { data },
  },
}) => {
  useEffect(() => {
    // scroll top
    getWindow().document.body.scrollTop = 0

    // Syntax highlighting
    Prism.highlightAll()

    // Image expand
    const imgs = document.querySelectorAll('img')
    if (imgs) {
      imgs.forEach(img =>
        img.addEventListener('click', () => {
          img.classList.toggle('zoomed')
        })
      )
    }
  }, [])

  const title = data.title.raw[0].text

  return (
    <Layout>
      <Seo title={data.title.raw[0].text} />
      <article className="BlogPost">
        <Link to="/blog" className="BlogPost_viewMore">
          ← View more blog posts
        </Link>
        <header>
          <h2 className="BlogPost_title">{title}</h2>
          <MetaContent data={data} />
        </header>
        <main className="BlogPost_body">
          {data.body[0].items.map(slice => {
            // Adds <code></code> blocks between <pre></pre> blocks
            const a = slice.text.html.replace(/<pre>/gi, '<pre><code>')
            const b = a.replace(/<\/pre>/gi, '</code></pre>')
            // Removes language declaration and adds it to the className
            const c = b.replace(
              /<code>javascript/gi,
              '<code class="language-javascript">'
            )
            const d = c.replace(/<code>css/gi, '<code class="language-css">')
            const e = d.replace(/<code>html/gi, '<code class="language-html">')
            const f = e.replace(
              /`([a-zA-Z0-9_-]+)`/gi,
              '<span class="inline-code">$1</span>'
            )
            return <div dangerouslySetInnerHTML={{ __html: f }} />
          })}
        </main>
        <footer>
          <hr />
          <MetaContent data={data} />
          <Link
            to="/blog"
            className="BlogPost_viewMore BlogPost_viewMore--footer"
          >
            ← View more blog posts
          </Link>
        </footer>
      </article>
    </Layout>
  )
}

export default BlogPost
